import React, { useState, useEffect } from 'react';
import './styles.sass'; // Assure-toi d'importer ton fichier de style CSS

const Navbar = ({ threshold }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > threshold) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [threshold]);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={`navigationBar ${isVisible ? 'visible' : ''}`}>
            <div className="navigationBarContainer">
                <div className="navigationBarButton" onClick={() => scrollToSection('nos-livres')}>Nos Livres</div>
                <div className="navigationBarButton" onClick={() => scrollToSection('presse')}>Presse</div>
                <div className="navigationBarButton" onClick={() => scrollToSection('qui-sommes-nous')}>Qui sommes nous ?</div>
            </div>
        </div>
    );
};

export default Navbar;
