import React, { useState, useEffect } from 'react';
import banniere from "../../Design/Visuel/Banniere Home page.png";
import book from "../../Design/Visuel/Livre qui vole PNG.png";
import shadow from "../../Design/Visuel/ombre_svg.svg";
import arrow from "../../Design/Visuel/right-arrow.svg";
import QaASSE from "../../Design/Visuel/QAAsse.png";
import QaOM from "../../Design/Visuel/om accers rapide.png";
import { useIsMobile } from '../../utils'
import './styles.sass';

const Header = () => {

    const [bookOffset, setBookOffset] = useState({ xOffset: 0, yOffset: 0 });
    const [width, setWidth] = useState(Number(window.innerWidth));

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const bookStyle = {
        transform: `translate(${bookOffset.xOffset}px, ${bookOffset.yOffset}px)`
    };

    useEffect(() => {
        const handleMouseMove = (event) => {
            const { clientX, clientY } = event;
            const xOffset = (clientX / window.innerWidth - 0.5) * 30;
            const yOffset = (clientY / window.innerHeight - 0.5) * 30;
            setBookOffset({ xOffset, yOffset });
        };
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const isMobile = useIsMobile();

    const textToCopy = "boumkertv@gmail.com"

    const copyMail = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert("Pour tous vos projets graphiques et de développement,\nn'hésitez pas à prendre contact par mail. \n\nboumkertv@gmail.com\na bien été copiée dans le presse-papiers !");
        }).catch(err => {
          console.error('Erreur lors de la copie du texte : ', err);
        });
      };

    return (
        <div className="header">
            <div className='container'>
                {!isMobile && 
                    <div className='imageHeader'>
                        <img src={banniere} alt="Bannière d'accueil"/>
                        <div className='quickAcces'>
                            <div className='quickAccesTitle'>Accès rapide <img className='quickAccesArrow' src={arrow} alt="fleche de l'acces rapide"/></div>
                            <div className='quickAccesImages'>
                                <div className='quickAccesImagesDivLeft' onClick={() => scrollToSection('produit-asse')}>
                                    <img src={QaASSE} alt="acces rapide ASSE"/>                                
                                </div>
                                <div className='quickAccesImagesDivRight' onClick={() => scrollToSection('produit-om')}>
                                    <img src={QaOM} alt="acces rapide OM"/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className='mainHeader'>
                    <div className='title'>EDITIONS SCOTTY</div>
                    <div className='book'>
                        <img src={book} style={bookStyle} alt="Livre d'accueil"/>
                        <img className='bookShadow' src={shadow} alt='ombre du livre'/>
                    </div>
                    <div className='navigationHeader'>
                        <div className='navigationContainer'>
                            <div className='navigationButton' onClick={() => scrollToSection('nos-livres')}>Nos Livres</div>
                            <div className='navigationButton' onClick={() => scrollToSection('presse')}>Presse</div>
                            <div className='navigationButton' onClick={() => scrollToSection('qui-sommes-nous')}>Qui sommes nous ?</div>
                        </div>
                    </div>
                    <div className='headerFooter' onClick={() => copyMail()}>
                        <div className='headerFooterCopyright'>
                            © 2024 Editions Scotty. Tous droits réservés.
                        </div>
                        <div className='headerFooterCredit'>
                            Conception graphique et développement : Boumker
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
