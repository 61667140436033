import React, { useState } from 'react';
import banniere from "../../Design/Visuel/Photo produi 1 ASSE.png";
import author from "../../Design/Visuel/Icon ‚crivant.png";
import page from "../../Design/Visuel/icon nompbre de page.png";
import date from "../../Design/Visuel/books_1f4da.png";
import box from "../../Design/Visuel/Icon frais denvoie.png";
import gift from "../../Design/Visuel/wrapped-gift_1f381.png";
import banniereInfo from "../../Design/Visuel/en savoir plus v5 ASSE.png";
import { useIsMobile } from '../../utils';
import './styles.sass';

const ProductASSE = () => {
    const [info, setInfo] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

    const showInfo = () => {
        setInfo(!info);
    };

    const handleClick = () => {
        window.location.href = 'https://www.paypal.com/ncp/payment/2R2834CV56P4N';
    };

    const isMobile = useIsMobile();

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };

    const handleMouseMove = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPosition({ x, y });
    };

    return (
        <div id='produit-asse' className="Product">
            <div className='container'>
                <div className='mainProduct'>
                    <div className='informationSection'>
                        <div className='title'>LES 50 MEILLEURS JOUEURS DE L'ASSE</div>
                        <div className='subtitle'>PLONGEZ AU CŒUR DU MYTHE</div>
                        <div className='bookInformation'>
                            <div className='bookInformationText'><img src={author} alt='autheur' />Benjamin DANET</div>
                            <div className='bookInformationText'><img src={page} alt='autheur' />250 pages</div>
                            <div className='bookInformationText'><img src={date} alt='autheur' />Sortie : Décembre 2022</div>
                        </div>
                        <div className='barSeparation' />
                        <div className='bookInformation'>
                            <div className='bookInformationTextBlack'>22 euros</div>
                            {/* <div className='bookInformationTextBlack' style={{ fontWeight: '500' }}><img src={gift} alt='autheur' />18 euros - Spécial fête des pères</div> */}
                            <div className='bookInformationText' style={{ fontWeight: '500' }}><img src={box} alt='autheur' />6,80 euros – Frais d'envoi</div>
                        </div>
                        <div className='payButtonDiv'>
                            <div className='payButton' onClick={() => handleClick()}>
                                Ajouter ● 28,80 euros TTC
                            </div>
                        </div>
                    </div>
                    {!isMobile &&
                        <div className='moreInfo'>
                            <div className='text' onClick={showInfo}>En savoir plus</div>
                            <div className='text' style={{ fontWeight: 'bold', width: '23px' }} onClick={showInfo}>{!info ? ">" : "X"}</div>
                        </div>
                    }
                </div>
                {!isMobile &&
                    <div className='productImage'>
                        <img 
                            src={banniere} 
                            alt="Bannière d'accueil" 
                            className={!info ? '' : 'hidden'} 
                            style={{objectFit: 'cover', objectPosition: 'center'}}
                        />
                        <img 
                            src={banniereInfo} 
                            alt="Bannière d'accueil" 
                            className={`banniereInfo ${info ? '' : 'hidden'} ${isZoomed ? 'zoomed' : ''}`} 
                            onMouseEnter={handleMouseEnter} 
                            onMouseLeave={handleMouseLeave} 
                            onMouseMove={handleMouseMove}
                            style={{
                                transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default ProductASSE;
