import React from 'react';
import ProductASSE from './Components/ProductASSE/ProductASSE';
import ProductOM from './Components/ProductOM/ProductOM';
import Header from './Components/Header/Header';
import NavBar from './Components/NavBar/NavBar';
import Contact from './Components/Contact/Contact';
import Books from './Components/Books/Books';
import Presse from './Components/Presse/Presse';
import { useIsMobile } from './utils'
import './App.css';

function App() {

    const isMobile = useIsMobile();
    return (
        <div className="App">
            <NavBar threshold={850} />
            <Header />
            <Books />
            <ProductASSE />
            {!isMobile && <div className='barSeparationProduct' />}
            <ProductOM />
            <Presse />
            <Contact />
        </div>
    );
}

export default App;
