import React, { useState } from 'react';
import asse from "../../Design/Visuel/nosLivreASSE.png"
import om from "../../Design/Visuel/nosLivreOM.png"
import psg from "../../Design/Visuel/nosLivrePSG.png"
import asseHover from "../../Design/Visuel/nosLivreASSEHover.png"
import omHover from "../../Design/Visuel/nosLivreOMHover.png"
import shadow from "../../Design/Visuel/ombreBooksSection.svg"
import { useIsMobile } from '../../utils'
import './styles.sass'

const Books = () => {
    const [currentImage, setCurrentImage] = useState({
        asse: asse,
        om: om
    });

    const handleMouseEnter = (book) => {
        if (book === 'asse') {
            setCurrentImage(prevState => ({ ...prevState, asse: asseHover }));
        } else if (book === 'om') {
            setCurrentImage(prevState => ({ ...prevState, om: omHover }));
        }
    };

    const handleMouseLeave = (book) => {
        if (book === 'asse') {
            setCurrentImage(prevState => ({ ...prevState, asse: asse }));
        } else if (book === 'om') {
            setCurrentImage(prevState => ({ ...prevState, om: om }));
        }
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const isMobile = useIsMobile();

    return (
        <div id='nos-livres' className="books">
            <div className='container'>
                <div className='booksList'>
                    <div className='book' onClick={() => scrollToSection('produit-asse')}>
                        <div className='bookName'>
                            <div className='title'>LES 50 MEILLEURS JOUEURS DE L'ASSE</div>
                            <div className='subtitle'>PLONGEZ AU CŒUR DU MYTHE</div>
                        </div>
                        <img 
                            src={currentImage.asse} 
                            alt='ImageASSE' 
                            onMouseEnter={() => handleMouseEnter('asse')} 
                            onMouseLeave={() => handleMouseLeave('asse')} 
                        />
                        <div className='ombre'>
                            <img src={shadow} alt='ombre' />
                        </div>
                    </div>
                    <div className='book' onClick={() => scrollToSection('produit-om')}>
                        <div className='bookName'>
                            <div className='title'>LES 50 MEILLEURS JOUEURS DE L'OM</div>
                            <div className='subtitle'>REVIVEZ UN SIECLE D'AMOUR FOOT</div>
                        </div>
                        <img 
                            src={currentImage.om} 
                            alt='ImageOM' 
                            onMouseEnter={() => handleMouseEnter('om')} 
                            onMouseLeave={() => handleMouseLeave('om')} 
                        />
                        <div className='ombre'>
                            <img src={shadow} alt='ombre' />
                        </div>
                    </div>
                    <div className='book' style={!isMobile ? {position: 'relative', top: '122px'} : {}}>
                        <img src={psg} alt='ImageOM' />
                        <div className='ombre'>
                            <img src={shadow} alt='ombre' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Books;
