// src/Design/Photo carouselle/images.js

// Importez toutes vos images ici
import img1 from '../../Design/new carouselle/dazfazf.png';
import img2 from '../../Design/new carouselle/dfsgfdsg.png';
import img3 from '../../Design/new carouselle/dsfgdsfg.png';
import img4 from '../../Design/new carouselle/dsfzf.png';
import img5 from '../../Design/new carouselle/dsgfzg.png';
import img6 from '../../Design/new carouselle/dssdg.png';
import img7 from '../../Design/new carouselle/edszgezrsg.png';
import img8 from '../../Design/new carouselle/egzgzeg.png';
import img9 from '../../Design/new carouselle/eqdzfg.png';
import img10 from '../../Design/new carouselle/esgsdgg.png';
import img11 from '../../Design/new carouselle/eszfgzegf.png';
import img12 from '../../Design/new carouselle/fdgdfhg.png';
import img13 from '../../Design/new carouselle/ffds.png';
import img14 from '../../Design/new carouselle/fqsfqf.png';
import img15 from '../../Design/new carouselle/fsdfzef.png';
import img16 from '../../Design/new carouselle/fsfsf.png';
import img17 from '../../Design/new carouselle/fzaefzgf.png';
import img18 from '../../Design/new carouselle/fzafzef.png';
import img19 from '../../Design/new carouselle/fzefezf.png';
import img20 from '../../Design/new carouselle/gergerg.png';
import img21 from '../../Design/new carouselle/gerzgegh.png';
import img22 from '../../Design/new carouselle/qzafzaeqf.png';
import img23 from '../../Design/new carouselle/rdfehgerherh.png';
import img24 from '../../Design/new carouselle/Sans titre-1.png';
import img25 from '../../Design/new carouselle/sdegzesg.png';
import img26 from '../../Design/new carouselle/sdsdgsdg.png';
import img27 from '../../Design/new carouselle/sfzafazqf.png';
import img28 from '../../Design/new carouselle/sqdfqsf.png';
import img29 from '../../Design/new carouselle/sqs.png';
import img30 from '../../Design/new carouselle/zefzefzf.png';
import img31 from '../../Design/new carouselle/zgzegzeg.png';
import img32 from '../../Design/new carouselle/zqfezaf.png';

export default [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
];
