import React, { useRef, useEffect } from 'react';
import banniere from "../../Design/Visuel/ensamble de photo carré.png";
import { Carousel } from 'react-responsive-carousel';
import Player from '@vimeo/player';
import YouTube from 'react-youtube';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles.sass';
import { useIsMobile } from '../../utils';
import images from './images';

const CustomPrevButton = ({ onClick, handleMedia }) => (
    <div onClick={() => { handleMedia(); onClick(); }} className="custom-button">
        {'<'}
    </div>
);

const CustomNextButton = ({ onClick, handleMedia }) => (
    <div onClick={() => { handleMedia(); onClick(); }} className="custom-button">
        {'>'}
    </div>
);

const vimeoVideoId1 = "955672108";
const vimeoVideoId2 = "956839202";
const vimeoVideoId3 = "956840620";
const youtubeVideoId = "MLjgeZj-Ekw";
const audioUrl1 = require("../../Design/Video carouselle/Benjamin Danet sur France Bleu Saint-Etienne 310123.mp3");
const audioUrl2 = require("../../Design/Video carouselle/Danet Radio Activ 100223 8h00 (online-audio-converter.com).mp3");
const audioUrl3 = require("../../Design/Video carouselle/Danet Radio Activ 100223 8h30 (online-audio-converter.com).mp3");
const audioUrl4 = require("../../Design/Video carouselle/Lamperti-FB-Provence.mp3");

const Presse = () => {
    const isMobile = useIsMobile();
    const vimeoPlayerRef1 = useRef(null);
    const vimeoPlayerRef2 = useRef(null);
    const vimeoPlayerRef3 = useRef(null);
    const youtubePlayerRef = useRef(null);
    const audioRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        try {
            vimeoPlayerRef1.current = new Player('vimeo-player-1', {
                id: vimeoVideoId1,
                width: '100%',
                height: '500px',
                autoplay: false,
                muted: false,
            });

            vimeoPlayerRef2.current = new Player('vimeo-player-2', {
                id: vimeoVideoId2,
                width: '100%',
                height: '500px',
                autoplay: false,
                muted: false,
            });

            vimeoPlayerRef3.current = new Player('vimeo-player-3', {
                id: vimeoVideoId3,
                width: '100%',
                height: '500px',
                autoplay: false,
                muted: false,
            });
        } catch (error) {
            console.error("Error initializing Vimeo players:", error);
        }

        return () => {
            try {
                if (vimeoPlayerRef1.current) {
                    vimeoPlayerRef1.current.unload();
                }
                if (vimeoPlayerRef2.current) {
                    vimeoPlayerRef2.current.unload();
                }
                if (vimeoPlayerRef3.current) {
                    vimeoPlayerRef3.current.unload();
                }
            } catch (error) {
                console.error("Error unloading Vimeo players:", error);
            }
        };
    }, []);

    const handleMedia = () => {
        try {
            if (vimeoPlayerRef1.current) {
                vimeoPlayerRef1.current.pause().catch((error) => {
                    console.error("Error pausing Vimeo video 1:", error);
                });
            }
            if (vimeoPlayerRef2.current) {
                vimeoPlayerRef2.current.pause().catch((error) => {
                    console.error("Error pausing Vimeo video 2:", error);
                });
            }
            if (vimeoPlayerRef3.current) {
                vimeoPlayerRef3.current.pause().catch((error) => {
                    console.error("Error pausing Vimeo video 3:", error);
                });
            }
            if (youtubePlayerRef.current && youtubePlayerRef.current.pauseVideo) {
                youtubePlayerRef.current.pauseVideo();
            }
            audioRefs.forEach((ref) => {
                if (ref.current) {
                    ref.current.pause();
                }
            });
        } catch (error) {
            console.error("Error handling media:", error);
        }
    };

    const onYouTubeReady = (event) => {
        youtubePlayerRef.current = event.target;
    };

    const handleCarouselChange = () => {
        handleMedia();
    };

    return (
        <div id='presse' className="Presse">
            <div className='container'>
                <div className='mainPresse'>
                    <div className='presseInformation'>
                        <div className='title'>PRESSE</div>
                        <div className='subtitle'>VU, LU, ENTENDU</div>
                    </div>
                    <div className='carousel'>
                        <Carousel 
                            showArrows={!isMobile} 
                            showThumbs={false} 
                            showStatus={false} 
                            showIndicators={true}
                            renderArrowPrev={(onClickHandler) =>
                                !isMobile && <CustomPrevButton onClick={onClickHandler} handleMedia={handleMedia} />
                            }
                            renderArrowNext={(onClickHandler) =>
                                !isMobile && <CustomNextButton onClick={onClickHandler} handleMedia={handleMedia} />
                            }
                            onChange={handleCarouselChange}
                        >
                            <div>
                                <div id="vimeo-player-1" style={{ width: '100%', height: '500px' }}></div>
                            </div>
                            <div>
                                <div id="vimeo-player-2" style={{ width: '100%', height: '500px' }}></div>
                            </div>
                            <div>
                                <div id="vimeo-player-3" style={{ width: '100%', height: '500px' }}></div>
                            </div>
                            <div>
                                <YouTube
                                    videoId={youtubeVideoId}
                                    opts={{
                                        width: '100%',
                                        height: '500px',
                                        playerVars: { autoplay: 0, mute: 0 },
                                    }}
                                    onReady={onYouTubeReady}
                                />
                            </div>
                            {images && images.map((src, index) => (
                                <div key={index}>
                                    <img src={src} alt={`Image ${index + 1}`} />
                                </div>
                            ))}
                            <div className='audio'>
                                <audio ref={audioRefs[0]} controls>
                                    <source src={audioUrl1} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                            <div className='audio'>
                                <audio ref={audioRefs[1]} controls>
                                    <source src={audioUrl2} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                            <div className='audio'>
                                <audio ref={audioRefs[2]} controls>
                                    <source src={audioUrl3} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                            <div className='audio'>
                                <audio ref={audioRefs[3]} controls>
                                    <source src={audioUrl4} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        </Carousel>
                    </div>
                </div>
                {!isMobile &&
                    <div className='imagePresse'>
                        <img src={banniere} alt="Images presse"/>
                    </div>
                }
            </div>
        </div>
    );
};

export default Presse;
