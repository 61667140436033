import React from 'react';
import logo from "../../Design/Visuel/Nouveau projet (1).svg"
import mail from "../../Design/Visuel/mail New.png"
import twitter from "../../Design/Visuel/X New.png"
import facebook from "../../Design/Visuel/fb New.png"
import './styles.sass'

const Contact = () => {

    const textToCopy = "contact@editions-scotty.com"

    const copyMail = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert('Mail copié dans le presse-papier ! \ncontact@editions-scotty.com');
        }).catch(err => {
          console.error('Erreur lors de la copie du texte : ', err);
        });
      };

    const redirectToFacebook = () => {
        window.open('https://www.facebook.com/profile.php?id=61554138495148', '_blank', 'noopener,noreferrer');
    };
    const redirectToX = () => {
        window.open('https://www.x.com/@editions_scotty', '_blank', 'noopener,noreferrer');
    };

    return (
        <div id='nos-reseaux' className="contact">
            <div id='qui-sommes-nous' className='container'>
                <div className='mainContact'>
                    <img className='logo' src={logo} alt='logo editions scotty' />
                    <div className='title'>EDITIONS SCOTTY</div>
                    <div className='blocDescription'>
                        <div className='description'><div className='firstLine'>Les Editions Scotty, du nom du héros du film<div className='vertigo'>Vertigo</div>d’Alfred Hitchcock,</div>publient des livres dans le domaine de la culture, des loisirs et du divertissement. Nous privilégions l’histoire, la trajectoire humaine et la dimension affective.</div>
                    </div>
                    <div className='barSeparation' />
                    <div className='address'>
                        <p>🏠 144 avenue Charles de Gaulle, 92200 Neuilly-sur-Seine</p>
                    </div>
                    <div className='reseau'>
                        <div onClick={() => copyMail()}>
                            <img src={mail} alt='mail'/>
                        </div>
                        <div onClick={() => redirectToX()}>
                            <img src={twitter} alt='x'/>
                        </div>
                        <div onClick={() => redirectToFacebook()}>
                            <img src={facebook} alt='facebook'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
